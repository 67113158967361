import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import {
  Status,
  useGetPropertyOrdersQuery,
  useDineInListQuery,
  useChangeOrderStatusMutation,
  useModifyOrderMutation,
  useCancelOrderMutation,
  useGetPropertyDetailsQuery,
} from "../../generated/graphql";

import { useAuthProvider } from "../../core/authContext";
import _ from "lodash";

const useDashboard = () => {
  const [changeOrderStatus] =
    useChangeOrderStatusMutation();

  const [modifyOrder] =
    useModifyOrderMutation();

  const [canselOrder] =
    useCancelOrderMutation();

  const [Neworder, setNewOrder] = useState<any>();
  const [OrderInprogress, setOrderInProgress] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const { getUser } = useAuthProvider();
  const user = getUser();

  const { data: propertyDetails} =
    useGetPropertyDetailsQuery({
      fetchPolicy: 'network-only',
      variables: {
        propertyID: user?.id,
      },
    }); 

  //Fetch Menu Items
  const { data: dineInList, loading: loadingDinInList } = useDineInListQuery({
    variables: {
      propertyID: user?.id,
    },
  });

  //fetch Pending Orders
  const {
    data: newOrders,
    loading: loadingNewOrders,
    refetch: reFetchNewOrders,
  } = useGetPropertyOrdersQuery({
    fetchPolicy: "network-only",
    variables: {
      orderStatus: Status.Pending,
      propertyID: user?.id,
    },
  });
  //Fetch InProgress orders
  const {
    data: inProgressOrders,
    loading: loadingInProgressOrders,
    refetch,
  } = useGetPropertyOrdersQuery({
    fetchPolicy: "network-only",
    variables: {
      orderStatus: Status.InProgress,
      propertyID: user?.id,
    },
  });

  useEffect(() => {
    const intervalCall = setInterval(() => {
      reFetchNewOrders();
    }, 5000);
    return () => {
      // clean up
      clearInterval(intervalCall);
    };
  }, [reFetchNewOrders]);

  // fetch cartitem in neworders
  useEffect(() => {
    if (newOrders?.getOrdersByStatus && dineInList?.getEdibles) {
      const test = newOrders?.getOrdersByStatus;
      const MenuList = test?.map((order) => {
        const item = order?.cart_items?.map((v) => {
          const list = dineInList?.getEdibles?.find(
            (item) => item?.id === v?.dineInItem_id
          );
          return { ...v, ...list };
        });
        return { ...order, cart_items: item };
      });

      setNewOrder(MenuList);
    } else {
      setNewOrder([]);
    }
  }, [newOrders, dineInList]);

  // fetch cart item in orderIn-Progress
  useEffect(() => {
    if (inProgressOrders?.getOrdersByStatus && dineInList?.getEdibles) {
      const test = inProgressOrders?.getOrdersByStatus;
      const Menu = test.map((ord) => {
        const Items = ord?.cart_items?.map((v) => {
          const list = dineInList?.getEdibles?.find(
            (item) => item?.id === v.dineInItem_id
          );
          return { ...v, ...list };
        });
        return { ...ord, cart_items: Items };
      });
      setOrderInProgress(Menu);
    } else {
      setOrderInProgress([]);
    }
  }, [inProgressOrders, dineInList]);

  //Accept Order
  const handleClickAcceptOrder = async (id: string) => {
    try {
      const res = await changeOrderStatus({
        variables: {
          id: id,
          propertyID: user?.id,
          orderStatus: Status.InProgress,
        },
      });

      if (res.data?.changeOrderStatus) {
        swal({
          text: "OrderInProgress Successfully",
          icon: "success",
        });
      } else {
        swal({
          text: "Order Are Not InProgress",
          icon: "error",
        });
      }
      reFetchNewOrders();
      refetch();
    } catch (err) {
      swal({
        text: `Error -${err}`,
        icon: "error",
      });
    }
  };

  //Delivered Order
  const handleClickCompleteOrder = async (id: string) => {
    try {
      const res = await changeOrderStatus({
        variables: {
          id: id,
          propertyID: user?.id,
          orderStatus: Status.Delivered,
        },
      });

      if (res.data?.changeOrderStatus) {
        swal({
          text: "Order Delivered Successfully",
          icon: "success",
        });
      } else {
        swal({
          text: "Order Not Delivered",
          icon: "error",
        });
      }
      reFetchNewOrders();
      refetch();
    } catch (err) {
      swal({
        text: `Error -${err}`,
        icon: "error",
      });
    }
  };

  const handleEditItems = async (items: any) => {
    const orderData = items?.cart_items?.map((cartData: any) => {
      let extras = cartData?.extras?.map((extra: any) => ({
        name: extra.name,

        price: extra.price,
      }));
      return {
        dineInItem_id: cartData.id,
        quantity: cartData.quantity,
        extras:extras,
        dineInItem_name: cartData.name,
        dineInItem_price: cartData.price,
      };
    });
    

    try {
      const res = await modifyOrder({
        variables: {
          orderInput: {
            id: items?.id,
            order_id: items?.order_id,
            cart_items: orderData,
            property_id: items?.property_id,
            guest_id: items?.guest_id,
            guest_name: items?.guest_name,
            room_id: items?.room_id,
            total: items.total,
            status: Status.Pending,
            created_at: items?.created_at,
          },
        },
      });
      setOpen(false);

      if (res.data) {
        swal({
          text: "Order Modify Successfully",
          icon: "success",
        });
      } else {
        swal({
          text: "Order Not Modified",
          icon: "error",
        });
      }
      reFetchNewOrders();
      refetch();
    } catch (err) {
      swal({
        text: `Error -${err}`,
        icon: "error",
      });
    }
  };

  const handleCancelOrder = async (id: string) => {
    try {
      await canselOrder({
        variables: {
          id: id,
          propertyID: user?.id,
        },
      });

      swal({
        text: "canceled order Successfully",
        icon: "success",
      });
      reFetchNewOrders();
      refetch();
    } catch (err) {
      swal({
        text: `${err}`,
        icon: "error",
      });
    }
  };
  //sort new orders
  const sortNewOrder = Neworder?.sort((a: any, b: any) => {
    return new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf();
  });

  const sortProgressOrder = OrderInprogress?.sort((a: any, b: any) => {
    return new Date(b.updated_at).valueOf() - new Date(a.updated_at).valueOf();
  });

  const [dineInItemsById, setDineInItemsById] = useState<any>();
  useEffect(() => {
    if (dineInList?.getEdibles?.length) {
      const byKey = _.keyBy(dineInList?.getEdibles, function (o) {
        return o?.id;
      });
      setDineInItemsById(byKey);
    }
  }, [dineInList]);

  return {
    details:propertyDetails?.getProperty,
    Neworder: sortNewOrder,
    dineInList,
    dineInItemsById,
    OrderInprogress: sortProgressOrder,
    inProgressOrders,
    loading: loadingNewOrders || loadingInProgressOrders || loadingDinInList,
    handleClickAcceptOrder,
    handleClickCompleteOrder,
    handleEditItems,
    handleCancelOrder,
    open,
    setOpen,
  };
};

export default useDashboard;

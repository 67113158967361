import React,{ useState,useRef } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  Avatar,
  Box,
  CardHeader,
  Chip,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import useOrderHistory from "./order-hook";
import Loader from "../../components/Loader";
import useDashboard from "../Dashboard/dashboard-hook";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#21BFAE",
    color: theme.palette.common.white,
    fontSize: 20,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Orders = () => {
  const {details}=useDashboard()
  const { orderHistory, loadingCancelOrder, loadingCompletedOrder } =
    useOrderHistory();

  const [page, setPage] = useState(1);
  // Logic for displaying current cards
  const rowPerPage = 10;
  const count = Math.round(orderHistory?.length / rowPerPage);
  const indexOfLastRow = page * rowPerPage;
  const indexOfFirstRow = indexOfLastRow - rowPerPage;
  const currentRows = orderHistory?.slice(indexOfFirstRow, indexOfLastRow);

  const myRef = useRef(null);
  const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    scrollToRef(myRef);
  };

  if (loadingCancelOrder && loadingCompletedOrder) {
    return <Loader />;
  }

  const getTimeDiff = (created_at:string, updated_at: string) => {
    const diff = new Date(updated_at).valueOf() - new Date(created_at).valueOf();
    const diffSeconds = diff/1000;
    let hours = Math.floor(diffSeconds / 3600)
    const hrString = hours.toString().padStart(2, '0');
    let minutes = Math.floor((diffSeconds - (hours * 3600)) / 60)
    const minString = minutes.toString().padStart(2, '0');
    let seconds = Math.floor(diffSeconds - (hours * 3600) - (minutes * 60))
    const secString = seconds.toString().padStart(2, '0');
    return `${hrString}:${minString}:${secString}`;
  }   

  return (
    <>
      {orderHistory?.length > 0 ? (
        <>
          <Typography
            variant="h4"
            gutterBottom
            sx={{ textAlign: "center", mt: 12, fontWeight: "bold" }}
          >
            Order History
          </Typography>
          <TableContainer component={Paper} sx={{ ml: 7, mt: 5 }}>
            <Table
              sx={{ minWidth: 700, width: "95%" }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">OrderID</StyledTableCell>
                  <StyledTableCell align="left">Order Date</StyledTableCell>
                  <StyledTableCell align="left">Name</StyledTableCell>
                  <StyledTableCell align="left">Room Detail</StyledTableCell>
                  <StyledTableCell align="left">Time</StyledTableCell>
                  <StyledTableCell align="left">Order Status</StyledTableCell>
                  <StyledTableCell align="left">Total Amount</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentRows?.map((history: any) => (
                  <StyledTableRow key={history?.id}>
                    <StyledTableCell align="left">
                      #{history?.order_id}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {new Date(history?.created_at).toLocaleDateString()}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <CardHeader
                        avatar={
                          <Avatar
                            alt={history?.guest_name}
                            src="/static/images/avatar/1.jpg"
                          />
                        }
                        title={history?.guest_name}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {history?.room_id}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Stack direction="row" alignItems="center" gap={1} mb={1}>
                        <AccessTimeIcon />
                        <Typography>
                          { 
                            getTimeDiff(history?.created_at, history?.updated_at)
                          }
                        </Typography>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {history?.status === "CANCELLED" ? (
                        <Chip label={history?.status} color="error" />
                      ) : (
                        <Chip label={history?.status} color="success" />
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                    {details?.currency}{history?.total}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            m={2}
          >
            <Pagination
              page={page}
              count={count}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        </>
      ) : (
        <>
          <Box
            style={{
              height: "10%",
              width: "10%",
              marginLeft: "45%",
              marginTop: "200px",
            }}
            component="img"
            alt="history"
            src="/assets/orderHistory.png"
          />
          <Typography sx={{ textAlign: "center", fontSize: 25 }}>
            There is no OrderHistory
          </Typography>
        </>
      )}
    </>
  );
};

export default Orders;

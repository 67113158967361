
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  InputBase,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import React, { useState } from "react";
import { Box } from "@mui/system";
import { DineInItem } from "../../generated/graphql";
import useDashboard from "../../pages/Dashboard/dashboard-hook";


const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
}));
const AddItem = ({ newOrder, setNewOrder }: any) => {
  const { dineInList } = useDashboard();

  const [filterdata, setFilterData] = useState<any>("");
  const [searchText, setSearchText] = useState<string>("");

  // <---------srarch the dine-in item---------->
  const handleFilter = (event: any) => {
    const search = event.target.value;
    const newFilter: any = dineInList?.getEdibles?.filter((val) => {
      return val?.name.toLowerCase().includes(search.toLowerCase());
    });
    if (search === "") {
      setFilterData([""]);
    } else setFilterData(newFilter);
    setSearchText(search);
  };

  // <----set the filter data in search input----------->
  const onSearch = (serchterm: any) => {
    setFilterData(serchterm);
    setSearchText(serchterm.name);
  };

  // <-----add the filter data on the modify order--------->

  // TODO: Please check the logic here, how come setChecked was not used
  const [checked] = React.useState<any>(false);
  const [select, setSelect] = React.useState<any>([]);

  const handleChange = (event: any) => {
    const tuple = event.target.value.split(",");
    let updatedList = [...select];
    if (event.target.checked) {
      updatedList = [
        ...select,
        { name: tuple[0], price: parseFloat(tuple[1]) },
      ];
    } else {
      updatedList.splice(
        select.findIndex((i: any) => i.name === tuple[0]),
        1
      );
    }

    setSelect(updatedList);
  };

  const addItem = () => {
    if (!filterdata) {
    } else {
      let updatedOrder = { ...newOrder };

      const index = updatedOrder?.cart_items.findIndex(
        (p: any) => p.id === filterdata?.id
      );

      if (index >= 0) {
        const item = updatedOrder?.cart_items[index];
        updatedOrder.cart_items.splice(index, 1, {
          ...item,
          quantity: item?.quantity + 1,
          extras: select,
        });
      } else {
        updatedOrder.cart_items.push({
          ...filterdata,
          quantity: 1,
          extras: select,
        });
      }
      setNewOrder(updatedOrder);

      setSearchText("");
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" mt={1} mb={1}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search item…"
            value={searchText}
            onChange={handleFilter}
          />
        </Search>

        <Button
          onClick={addItem}
          size="small"
          color="success"
          variant="contained"
        >
          Add item
        </Button>
      </Stack>

      {filterdata.length !== 0 && (
        <Box>
          {Array.isArray(filterdata) &&
            filterdata?.map((v: DineInItem, key: number) => {
              return (
                <>
                  <div
                    key={key}
                    style={{ cursor: "pointer" }}
                    onClick={() => onSearch(v)}
                  >
                    {v?.name}
                  </div>
                  {v?.extraOptions?.map((ex:any, i: number) => {
                    return (
                      <Box>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={checked[i]}
                                value={[ex.name, ex.price]}
                                onChange={handleChange}
                              />
                            }
                            label={ex.name}
                          />
                        </FormGroup>
                      </Box>
                    );
                  })}
                  <Divider />
                </>
              );
            })}
        </Box>
      )}
    </>
  );
};

export default AddItem;
